import React, { lazy, Suspense } from "react";
import Loading from 'components/shared-components/Loading';

const Login = lazy(() => import(`./authentication/login`))

export const AppViews = () => (
  <Suspense fallback={<Loading cover="page"/>}>
    <Login />
  </Suspense>
)

export default AppViews;

