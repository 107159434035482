import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERRORS
} from '../constants/Auth'
import { TOKEN_PREFIX, REFRESH_TOKEN_PREFIX } from 'configs/AppConfig';

const DEFAULT_STATE = {
  error: null,
  user: null,
  token: null,
  refreshToken: null,
  loading: false,
}

export const authReducer = (state = DEFAULT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true }

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
				user: payload.data,
        token: payload.headers[TOKEN_PREFIX],
        refreshToken: payload.headers[REFRESH_TOKEN_PREFIX]
      }

    case LOGOUT_SUCCESS:
			return { ...DEFAULT_STATE }

    case LOGIN_FAIL:
      return { ...state, loading: false, error: payload }

    case LOGOUT_FAIL:
			return { ...state, error: payload }

    case CLEAR_ERRORS:
			return { ...state, error: null }

    default:
      return state
  }
}