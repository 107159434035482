import { combineReducers } from 'redux';
import Theme from './Theme';
import { authReducer } from './Auth';
import { profileDataReducer, updateMeReducer } from './Profile'
import { compoundStatisticsReducer, getDomHistogramDataReducer } from './Statistics'
import { allPackagesReducer, registerPackageReducer, packageDetailsReducer, packageReducer } from './Packages'
import { allOrganizationsReducer, registerOrganizationReducer, organizationDetailsReducer, organizationReducer } from './Organizations'
import { allCompoundsReducer, registerCompoundReducer, compoundDetailsReducer, compoundReducer } from './Compounds'
import { allInvoicesReducer, registerInvoiceReducer, invoiceDetailsReducer, invoiceReducer } from './Invoices'
import { allOwnersReducer, registerOwnerReducer, ownerDetailsReducer, ownerReducer } from './Owners'
import { allOperatorsReducer, registerOperatorReducer, operatorDetailsReducer, operatorReducer } from './Operators'

const reducers = combineReducers({
    theme: Theme,
    user: authReducer,

    profileData: profileDataReducer,
    updateMe: updateMeReducer,

    compoundStatistics: compoundStatisticsReducer,
    getDomHistogramData: getDomHistogramDataReducer,

    allPackages: allPackagesReducer,
    registerPackage: registerPackageReducer,
    packageDetails: packageDetailsReducer,
    package: packageReducer,

    allOrganizations: allOrganizationsReducer,
    registerOrganization: registerOrganizationReducer,
    organizationDetails: organizationDetailsReducer,
    organization: organizationReducer,

    allCompounds: allCompoundsReducer,
    registerCompound: registerCompoundReducer,
    compoundDetails: compoundDetailsReducer,
    compound: compoundReducer,

    allInvoices: allInvoicesReducer,
    registerInvoice: registerInvoiceReducer,
    invoiceDetails: invoiceDetailsReducer,
    invoice: invoiceReducer,

    allOwners: allOwnersReducer,
    registerOwner: registerOwnerReducer,
    ownerDetails: ownerDetailsReducer,
    owner: ownerReducer,

    allOperators: allOperatorsReducer,
    registerOperator: registerOperatorReducer,
    operatorDetails: operatorDetailsReducer,
    operator: operatorReducer
});

export default reducers;