export const ALL_OWNERS_REQUEST = 'ALL_OWNERS_REQUEST'
export const ALL_OWNERS_SUCCESS = 'ALL_OWNERS_SUCCESS'
export const ALL_OWNERS_FAIL = 'ALL_OWNERS_FAIL'

export const CREATE_OWNER_REQUEST = 'CREATE_OWNER_REQUEST'
export const CREATE_OWNER_SUCCESS = 'CREATE_OWNER_SUCCESS'
export const CREATE_OWNER_RESET = 'CREATE_OWNER_RESET'
export const CREATE_OWNER_FAIL = 'CREATE_OWNER_FAIL'

export const UPDATE_OWNER_REQUEST = 'UPDATE_OWNER_REQUEST'
export const UPDATE_OWNER_SUCCESS = 'UPDATE_OWNER_SUCCESS'
export const UPDATE_OWNER_RESET = 'UPDATE_OWNER_RESET'
export const UPDATE_OWNER_FAIL = 'UPDATE_OWNER_FAIL'

export const DELETE_OWNER_REQUEST = 'DELETE_OWNER_REQUEST'
export const DELETE_OWNER_SUCCESS = 'DELETE_OWNER_SUCCESS'
export const DELETE_OWNER_RESET = 'DELETE_OWNER_RESET'
export const DELETE_OWNER_FAIL = 'DELETE_OWNER_FAIL'

export const OWNER_DETAILS_REQUEST = 'OWNER_DETAILS_REQUEST'
export const OWNER_DETAILS_SUCCESS = 'OWNER_DETAILS_SUCCESS'
export const OWNER_DETAILS_FAIL = 'OWNER_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'