import React from 'react';
import { Provider } from 'react-redux';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { store } from './redux/store';
import { THEME_CONFIG } from './configs/AppConfig';
import Views from './views';
import './app.css';
import './sass/styles.css';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <Views />
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
