import axios from 'axios';
import { message } from "antd";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  CLEAR_ERRORS
} from '../constants/Auth'
import { getProfileData } from "redux/actions/Profile";
import { HOST } from "constants/ApiConstant";
import { TOKEN_PREFIX, REFRESH_TOKEN_PREFIX } from "configs/AppConfig";

export const login = (values, cb) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST })
    const data = await axios.post(`${HOST}/operator/signing/login`, values)
    message.success('Logged in successfully')
    axios.defaults.headers.common[TOKEN_PREFIX] = data.headers[TOKEN_PREFIX]
    axios.defaults.headers.common[REFRESH_TOKEN_PREFIX] = data.headers[REFRESH_TOKEN_PREFIX];
    dispatch({ type: LOGIN_SUCCESS, payload: data })
    dispatch(getProfileData())
    cb()
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error?.response?.data })
  }
}

export const logout = (cb) => async (dispatch) => {
  try {
    await axios.get(`${HOST}/operator/signing/logout`)
  } catch (error) {
    // dispatch({ type: LOGOUT_FAIL, payload: error.response.data })
  }
  dispatch({ type: LOGOUT_SUCCESS })
  cb()
}

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS })
}