import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        {/* HOME */}
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />

        {/* EDIT PROFILE */}
        <Route path={`${APP_PREFIX_PATH}/me/update`} component={lazy(() => import(`./editProfile`))} />

        {/* COMPOUNDS */}
        <Route path={`${APP_PREFIX_PATH}/compounds/create`} component={lazy(() => import(`./compounds/createCompound`))} />
        <Route path={`${APP_PREFIX_PATH}/compounds/update/:id`} component={lazy(() => import(`./compounds/updateCompound`))} />
        <Route path={`${APP_PREFIX_PATH}/compounds`} component={lazy(() => import(`./compounds`))} />

        {/* ORGANIZATION */}
        <Route path={`${APP_PREFIX_PATH}/organization/create`} component={lazy(() => import(`./organization/create`))} />
        <Route path={`${APP_PREFIX_PATH}/organization/update/:id`} component={lazy(() => import(`./organization/update`))} />
        <Route path={`${APP_PREFIX_PATH}/organization/:id`} component={lazy(() => import(`./organization/details`))} />
        <Route path={`${APP_PREFIX_PATH}/organization`} component={lazy(() => import(`./organization`))} />

        {/* PACKAGES */}
        <Route path={`${APP_PREFIX_PATH}/packages/create`} component={lazy(() => import(`./packages/create`))} />
        <Route path={`${APP_PREFIX_PATH}/packages/update/:id`} component={lazy(() => import(`./packages/update`))} />
        <Route path={`${APP_PREFIX_PATH}/packages`} component={lazy(() => import(`./packages`))} />

        {/* INVOICES */}
        <Route path={`${APP_PREFIX_PATH}/invoices/create`} component={lazy(() => import(`./invoices/create`))} />
        <Route path={`${APP_PREFIX_PATH}/invoices/update/:id`} component={lazy(() => import(`./invoices/update`))} />
        <Route path={`${APP_PREFIX_PATH}/invoices`} component={lazy(() => import(`./invoices`))} />

        {/* OWNER */}
        <Route path={`${APP_PREFIX_PATH}/owners/create`} component={lazy(() => import(`./owners/create`))} />
        <Route path={`${APP_PREFIX_PATH}/owners/update/:id`} component={lazy(() => import(`./owners/update`))} />
        <Route path={`${APP_PREFIX_PATH}/owners`} component={lazy(() => import(`./owners`))} />

        {/* OPERATOR */}
        <Route path={`${APP_PREFIX_PATH}/operators/create`} component={lazy(() => import(`./operator/create`))} />
        <Route path={`${APP_PREFIX_PATH}/operators/update/:id`} component={lazy(() => import(`./operator/update`))} />
        <Route path={`${APP_PREFIX_PATH}/operators`} component={lazy(() => import(`./operator`))} />

        {/* STATISTICS */}
        <Route path={`${APP_PREFIX_PATH}/home/statistics`} component={lazy(() => import(`./statistics`))} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);