import {
  ALL_OWNERS_REQUEST,
  ALL_OWNERS_SUCCESS,
  ALL_OWNERS_FAIL,
  CREATE_OWNER_REQUEST,
  CREATE_OWNER_SUCCESS,
  CREATE_OWNER_RESET,
  CREATE_OWNER_FAIL,
  UPDATE_OWNER_REQUEST,
  UPDATE_OWNER_SUCCESS,
  UPDATE_OWNER_RESET,
  UPDATE_OWNER_FAIL,
  DELETE_OWNER_REQUEST,
  DELETE_OWNER_SUCCESS,
  DELETE_OWNER_RESET,
  DELETE_OWNER_FAIL,
  OWNER_DETAILS_REQUEST,
  OWNER_DETAILS_SUCCESS,
  OWNER_DETAILS_FAIL,
  CLEAR_ERRORS
} from '../constants/Owners'

export const registerOwnerReducer = (state = {}, action) => {
  switch (action.type) {

    case CREATE_OWNER_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_OWNER_SUCCESS:
      return {
        loading: false,
        owner: action.payload
      }

    case CREATE_OWNER_RESET:
      return {
        loading: false,
        owner: null
      }
    
    case CREATE_OWNER_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const allOwnersReducer = (state = { owners: {} }, action) => {
  switch (action.type) {

    case ALL_OWNERS_REQUEST:
      return {
        loading: true
      }

    case ALL_OWNERS_SUCCESS:
      return {
        loading: false,
        owners: action.payload.items,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        itemsTotalCount: action.payload.itemsTotalCount
      }

    case ALL_OWNERS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const ownerDetailsReducer = (state = {}, action) => {
  switch (action.type) {

    case OWNER_DETAILS_REQUEST:
      return {
        loading: true
      }

    case OWNER_DETAILS_SUCCESS:
      return {
        loading: false,
        owner: action.payload
      }

    case OWNER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const ownerReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_OWNER_REQUEST:
    case DELETE_OWNER_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true
      }

    case UPDATE_OWNER_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false
      }

    case DELETE_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true
      }

    case DELETE_OWNER_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false
      }

    case UPDATE_OWNER_FAIL:
    case DELETE_OWNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}
