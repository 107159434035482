import {
  ALL_PACKAGES_REQUEST,
  ALL_PACKAGES_SUCCESS,
  ALL_PACKAGES_FAIL,
  CREATE_PACKAGE_REQUEST,
  CREATE_PACKAGE_SUCCESS,
  CREATE_PACKAGE_RESET,
  CREATE_PACKAGE_FAIL,
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_RESET,
  UPDATE_PACKAGE_FAIL,
  DELETE_PACKAGE_REQUEST,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_RESET,
  DELETE_PACKAGE_FAIL,
  PACKAGE_DETAILS_REQUEST,
  PACKAGE_DETAILS_SUCCESS,
  PACKAGE_DETAILS_FAIL,
  CLEAR_ERRORS
} from '../constants/Packages'

export const registerPackageReducer = (state = {}, action) => {
  switch (action.type) {

    case CREATE_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_PACKAGE_SUCCESS:
      return {
        loading: false,
        package: action.payload
      }

    case CREATE_PACKAGE_RESET:
      return {
        loading: false,
        package: null
      }
    
    case CREATE_PACKAGE_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const allPackagesReducer = (state = { packages: [] }, action) => {
  switch (action.type) {

    case ALL_PACKAGES_REQUEST:
      return {
        loading: true
      }

    case ALL_PACKAGES_SUCCESS:
      return {
        loading: false,
        packages: action.payload.items,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        itemsTotalCount: action.payload.itemsTotalCount
      }

    case ALL_PACKAGES_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const packageDetailsReducer = (state = {}, action) => {
  switch (action.type) {

    case PACKAGE_DETAILS_REQUEST:
      return {
        loading: true
      }

    case PACKAGE_DETAILS_SUCCESS:
      return {
        loading: false,
        package: action.payload
      }

    case PACKAGE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const packageReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_PACKAGE_REQUEST:
    case DELETE_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true
      }

    case UPDATE_PACKAGE_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false
      }

    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true
      }

    case DELETE_PACKAGE_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false
      }

    case UPDATE_PACKAGE_FAIL:
    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}
