import {
  ALL_OPERATORS_REQUEST,
  ALL_OPERATORS_SUCCESS,
  ALL_OPERATORS_FAIL,
  CREATE_OPERATOR_REQUEST,
  CREATE_OPERATOR_SUCCESS,
  CREATE_OPERATOR_RESET,
  CREATE_OPERATOR_FAIL,
  UPDATE_OPERATOR_REQUEST,
  UPDATE_OPERATOR_SUCCESS,
  UPDATE_OPERATOR_RESET,
  UPDATE_OPERATOR_FAIL,
  DELETE_OPERATOR_REQUEST,
  DELETE_OPERATOR_SUCCESS,
  DELETE_OPERATOR_RESET,
  DELETE_OPERATOR_FAIL,
  OPERATOR_DETAILS_REQUEST,
  OPERATOR_DETAILS_SUCCESS,
  OPERATOR_DETAILS_FAIL,
  CLEAR_ERRORS
} from '../constants/Operators'

export const registerOperatorReducer = (state = {}, action) => {
  switch (action.type) {

    case CREATE_OPERATOR_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_OPERATOR_SUCCESS:
      return {
        loading: false,
        operator: action.payload
      }

    case CREATE_OPERATOR_RESET:
      return {
        loading: false,
        operator: null
      }
    
    case CREATE_OPERATOR_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const allOperatorsReducer = (state = { operators: [] }, action) => {
  switch (action.type) {

    case ALL_OPERATORS_REQUEST:
      return {
        loading: true
      }

    case ALL_OPERATORS_SUCCESS:
      return {
        loading: false,
        operators: action.payload.items,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        itemsTotalCount: action.payload.itemsTotalCount
      }

    case ALL_OPERATORS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const operatorDetailsReducer = (state = {}, action) => {
  switch (action.type) {

    case OPERATOR_DETAILS_REQUEST:
      return {
        loading: true
      }

    case OPERATOR_DETAILS_SUCCESS:
      return {
        loading: false,
        operator: action.payload
      }

    case OPERATOR_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const operatorReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_OPERATOR_REQUEST:
    case DELETE_OPERATOR_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_OPERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true
      }

    case UPDATE_OPERATOR_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false
      }

    case DELETE_OPERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true
      }

    case DELETE_OPERATOR_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false
      }

    case UPDATE_OPERATOR_FAIL:
    case DELETE_OPERATOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}
