export const ALL_PACKAGES_REQUEST = 'ALL_PACKAGES_REQUEST'
export const ALL_PACKAGES_SUCCESS = 'ALL_PACKAGES_SUCCESS'
export const ALL_PACKAGES_FAIL = 'ALL_PACKAGES_FAIL'

export const CREATE_PACKAGE_REQUEST = 'CREATE_PACKAGE_REQUEST'
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS'
export const CREATE_PACKAGE_RESET = 'CREATE_PACKAGE_RESET'
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL'

export const UPDATE_PACKAGE_REQUEST = 'UPDATE_PACKAGE_REQUEST'
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS'
export const UPDATE_PACKAGE_RESET = 'UPDATE_PACKAGE_RESET'
export const UPDATE_PACKAGE_FAIL = 'UPDATE_PACKAGE_FAIL'

export const DELETE_PACKAGE_REQUEST = 'DELETE_PACKAGE_REQUEST'
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS'
export const DELETE_PACKAGE_RESET = 'DELETE_PACKAGE_RESET'
export const DELETE_PACKAGE_FAIL = 'DELETE_PACKAGE_FAIL'

export const PACKAGE_DETAILS_REQUEST = 'PACKAGE_DETAILS_REQUEST'
export const PACKAGE_DETAILS_SUCCESS = 'PACKAGE_DETAILS_SUCCESS'
export const PACKAGE_DETAILS_FAIL = 'PACKAGE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'