import {
  ALL_INVOICES_REQUEST,
  ALL_INVOICES_SUCCESS,
  ALL_INVOICES_FAIL,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_RESET,
  CREATE_INVOICE_FAIL,
  UPDATE_INVOICE_REQUEST,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_RESET,
  UPDATE_INVOICE_FAIL,
  DELETE_INVOICE_REQUEST,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_RESET,
  DELETE_INVOICE_FAIL,
  INVOICE_DETAILS_REQUEST,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_DETAILS_FAIL,
  CLEAR_ERRORS
} from '../constants/Invoices'

export const registerInvoiceReducer = (state = {}, action) => {
  switch (action.type) {

    case CREATE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_INVOICE_SUCCESS:
      return {
        loading: false,
        invoice: action.payload
      }

    case CREATE_INVOICE_RESET:
      return {
        loading: false,
        invoice: null
      }
    
    case CREATE_INVOICE_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const allInvoicesReducer = (state = { invoices: [] }, action) => {
  switch (action.type) {

    case ALL_INVOICES_REQUEST:
      return {
        loading: true
      }

    case ALL_INVOICES_SUCCESS:
      return {
        loading: false,
        invoices: action.payload.items,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        itemsTotalCount: action.payload.itemsTotalCount
      }

    case ALL_INVOICES_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const invoiceDetailsReducer = (state = {}, action) => {
  switch (action.type) {

    case INVOICE_DETAILS_REQUEST:
      return {
        loading: true
      }

    case INVOICE_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice: action.payload
      }

    case INVOICE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const invoiceReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_INVOICE_REQUEST:
    case DELETE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true
      }

    case UPDATE_INVOICE_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false
      }

    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true
      }

    case DELETE_INVOICE_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false
      }

    case UPDATE_INVOICE_FAIL:
    case DELETE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}
