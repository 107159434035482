import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant'
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Harees'
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app'
export const HOME_PREFIX_PATH = APP_PREFIX_PATH + '/home'
export const AUTH_PREFIX_PATH = '/auth/login'
export const TOKEN_PREFIX = 'x-auth-token'
export const REFRESH_TOKEN_PREFIX = 'x-auth-refresh-token'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
