export const ALL_OPERATORS_REQUEST = 'ALL_OPERATORS_REQUEST'
export const ALL_OPERATORS_SUCCESS = 'ALL_OPERATORS_SUCCESS'
export const ALL_OPERATORS_FAIL = 'ALL_OPERATORS_FAIL'

export const CREATE_OPERATOR_REQUEST = 'CREATE_OPERATOR_REQUEST'
export const CREATE_OPERATOR_SUCCESS = 'CREATE_OPERATOR_SUCCESS'
export const CREATE_OPERATOR_RESET = 'CREATE_OPERATOR_RESET'
export const CREATE_OPERATOR_FAIL = 'CREATE_OPERATOR_FAIL'

export const UPDATE_OPERATOR_REQUEST = 'UPDATE_OPERATOR_REQUEST'
export const UPDATE_OPERATOR_SUCCESS = 'UPDATE_OPERATOR_SUCCESS'
export const UPDATE_OPERATOR_RESET = 'UPDATE_OPERATOR_RESET'
export const UPDATE_OPERATOR_FAIL = 'UPDATE_OPERATOR_FAIL'

export const DELETE_OPERATOR_REQUEST = 'DELETE_OPERATOR_REQUEST'
export const DELETE_OPERATOR_SUCCESS = 'DELETE_OPERATOR_SUCCESS'
export const DELETE_OPERATOR_RESET = 'DELETE_OPERATOR_RESET'
export const DELETE_OPERATOR_FAIL = 'DELETE_OPERATOR_FAIL'

export const OPERATOR_DETAILS_REQUEST = 'OPERATOR_DETAILS_REQUEST'
export const OPERATOR_DETAILS_SUCCESS = 'OPERATOR_DETAILS_SUCCESS'
export const OPERATOR_DETAILS_FAIL = 'OPERATOR_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'