export const ALL_INVOICES_REQUEST = 'ALL_INVOICES_REQUEST'
export const ALL_INVOICES_SUCCESS = 'ALL_INVOICES_SUCCESS'
export const ALL_INVOICES_FAIL = 'ALL_INVOICES_FAIL'

export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_RESET = 'CREATE_INVOICE_RESET'
export const CREATE_INVOICE_FAIL = 'CREATE_INVOICE_FAIL'

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_RESET = 'UPDATE_INVOICE_RESET'
export const UPDATE_INVOICE_FAIL = 'UPDATE_INVOICE_FAIL'

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST'
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS'
export const DELETE_INVOICE_RESET = 'DELETE_INVOICE_RESET'
export const DELETE_INVOICE_FAIL = 'DELETE_INVOICE_FAIL'

export const INVOICE_DETAILS_REQUEST = 'INVOICE_DETAILS_REQUEST'
export const INVOICE_DETAILS_SUCCESS = 'INVOICE_DETAILS_SUCCESS'
export const INVOICE_DETAILS_FAIL = 'INVOICE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'