export const ALL_ORGANIZATIONS_REQUEST = 'ALL_ORGANIZATIONS_REQUEST'
export const ALL_ORGANIZATIONS_SUCCESS = 'ALL_ORGANIZATIONS_SUCCESS'
export const ALL_ORGANIZATIONS_FAIL = 'ALL_ORGANIZATIONS_FAIL'

export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_RESET = 'CREATE_ORGANIZATION_RESET'
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL'

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_RESET = 'UPDATE_ORGANIZATION_RESET'
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL'

export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST'
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS'
export const DELETE_ORGANIZATION_RESET = 'DELETE_ORGANIZATION_RESET'
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL'

export const ORGANIZATION_DETAILS_REQUEST = 'ORGANIZATION_DETAILS_REQUEST'
export const ORGANIZATION_DETAILS_SUCCESS = 'ORGANIZATION_DETAILS_SUCCESS'
export const ORGANIZATION_DETAILS_FAIL = 'ORGANIZATION_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'