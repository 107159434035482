export const ALL_COMPOUNDS_REQUEST = 'ALL_COMPOUNDS_REQUEST'
export const ALL_COMPOUNDS_SUCCESS = 'ALL_COMPOUNDS_SUCCESS'
export const ALL_COMPOUNDS_FAIL = 'ALL_COMPOUNDS_FAIL'

export const CREATE_COMPOUND_REQUEST = 'CREATE_COMPOUND_REQUEST'
export const CREATE_COMPOUND_SUCCESS = 'CREATE_COMPOUND_SUCCESS'
export const CREATE_COMPOUND_RESET = 'CREATE_COMPOUND_RESET'
export const CREATE_COMPOUND_FAIL = 'CREATE_COMPOUND_FAIL'

export const UPDATE_COMPOUND_REQUEST = 'UPDATE_COMPOUND_REQUEST'
export const UPDATE_COMPOUND_SUCCESS = 'UPDATE_COMPOUND_SUCCESS'
export const UPDATE_COMPOUND_RESET = 'UPDATE_COMPOUND_RESET'
export const UPDATE_COMPOUND_FAIL = 'UPDATE_COMPOUND_FAIL'

export const DELETE_COMPOUND_REQUEST = 'DELETE_COMPOUND_REQUEST'
export const DELETE_COMPOUND_SUCCESS = 'DELETE_COMPOUND_SUCCESS'
export const DELETE_COMPOUND_RESET = 'DELETE_COMPOUND_RESET'
export const DELETE_COMPOUND_FAIL = 'DELETE_COMPOUND_FAIL'

export const COMPOUND_DETAILS_REQUEST = 'COMPOUND_DETAILS_REQUEST'
export const COMPOUND_DETAILS_SUCCESS = 'COMPOUND_DETAILS_SUCCESS'
export const COMPOUND_DETAILS_FAIL = 'COMPOUND_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'