import {
  ALL_COMPOUNDS_REQUEST,
  ALL_COMPOUNDS_SUCCESS,
  ALL_COMPOUNDS_FAIL,
  CREATE_COMPOUND_REQUEST,
  CREATE_COMPOUND_SUCCESS,
  CREATE_COMPOUND_RESET,
  CREATE_COMPOUND_FAIL,
  UPDATE_COMPOUND_REQUEST,
  UPDATE_COMPOUND_SUCCESS,
  UPDATE_COMPOUND_RESET,
  UPDATE_COMPOUND_FAIL,
  DELETE_COMPOUND_REQUEST,
  DELETE_COMPOUND_SUCCESS,
  DELETE_COMPOUND_RESET,
  DELETE_COMPOUND_FAIL,
  COMPOUND_DETAILS_REQUEST,
  COMPOUND_DETAILS_SUCCESS,
  COMPOUND_DETAILS_FAIL,
  CLEAR_ERRORS
} from '../constants/Compounds'

export const registerCompoundReducer = (state = {}, action) => {
  switch (action.type) {

    case CREATE_COMPOUND_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_COMPOUND_SUCCESS:
      return {
        loading: false,
        compound: action.payload
      }

    case CREATE_COMPOUND_RESET:
      return {
        loading: false,
        compound: null
      }
    
    case CREATE_COMPOUND_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const allCompoundsReducer = (state = { compounds: [] }, action) => {
  switch (action.type) {

    case ALL_COMPOUNDS_REQUEST:
      return {
        loading: true
      }

    case ALL_COMPOUNDS_SUCCESS:
      return {
        loading: false,
        compounds: action.payload.items,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        itemsTotalCount: action.payload.itemsTotalCount
      }

    case ALL_COMPOUNDS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const compoundDetailsReducer = (state = {}, action) => {
  switch (action.type) {

    case COMPOUND_DETAILS_REQUEST:
      return {
        loading: true
      }

    case COMPOUND_DETAILS_SUCCESS:
      return {
        loading: false,
        compound: action.payload
      }

    case COMPOUND_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const compoundReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_COMPOUND_REQUEST:
    case DELETE_COMPOUND_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_COMPOUND_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true
      }

    case UPDATE_COMPOUND_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false
      }

    case DELETE_COMPOUND_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true
      }

    case DELETE_COMPOUND_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false
      }

    case UPDATE_COMPOUND_FAIL:
    case DELETE_COMPOUND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}
