import {
  ALL_ORGANIZATIONS_REQUEST,
  ALL_ORGANIZATIONS_SUCCESS,
  ALL_ORGANIZATIONS_FAIL,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_RESET,
  CREATE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_RESET,
  UPDATE_ORGANIZATION_FAIL,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_RESET,
  DELETE_ORGANIZATION_FAIL,
  ORGANIZATION_DETAILS_REQUEST,
  ORGANIZATION_DETAILS_SUCCESS,
  ORGANIZATION_DETAILS_FAIL,
  CLEAR_ERRORS
} from '../constants/Organizations'

export const registerOrganizationReducer = (state = {}, action) => {
  switch (action.type) {

    case CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_ORGANIZATION_SUCCESS:
      return {
        loading: false,
        organization: action.payload
      }

    case CREATE_ORGANIZATION_RESET:
      return {
        loading: false,
        organization: null
      }
    
    case CREATE_ORGANIZATION_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const allOrganizationsReducer = (state = { organizations: [] }, action) => {
  switch (action.type) {

    case ALL_ORGANIZATIONS_REQUEST:
      return {
        loading: true
      }

    case ALL_ORGANIZATIONS_SUCCESS:
      return {
        loading: false,
        organizations: action.payload.items,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        itemsTotalCount: action.payload.itemsTotalCount
      }

    case ALL_ORGANIZATIONS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const organizationDetailsReducer = (state = {}, action) => {
  switch (action.type) {

    case ORGANIZATION_DETAILS_REQUEST:
      return {
        loading: true
      }

    case ORGANIZATION_DETAILS_SUCCESS:
      return {
        loading: false,
        organization: action.payload,
        invoices: action.payload._invoices,
        compounds: action.payload._compounds,
        owners: action.payload._owners,
      }

    case ORGANIZATION_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const organizationReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_ORGANIZATION_REQUEST:
    case DELETE_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true
      }

    case UPDATE_ORGANIZATION_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false
      }

    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true
      }

    case DELETE_ORGANIZATION_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false
      }

    case UPDATE_ORGANIZATION_FAIL:
    case DELETE_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}
