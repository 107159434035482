export const ORGANIZATION_STATISTICS_REQUEST = 'ORGANIZATION_STATISTICS_REQUEST'
export const ORGANIZATION_STATISTICS_SUCCESS = 'ORGANIZATION_STATISTICS_SUCCESS'
export const ORGANIZATION_STATISTICS_FAIL = 'ORGANIZATION_STATISTICS_FAIL'

export const TICKETS_STATISTICS_REQUEST = 'TICKETS_STATISTICS_REQUEST'
export const TICKETS_STATISTICS_SUCCESS = 'TICKETS_STATISTICS_SUCCESS'
export const TICKETS_STATISTICS_FAIL = 'TICKETS_STATISTICS_FAIL'

export const TICKETS_COUNT_DOM_REQUEST = 'TICKETS_COUNT_DOM_REQUEST'
export const TICKETS_COUNT_DOM_SUCCESS = 'TICKETS_COUNT_DOM_SUCCESS'
export const TICKETS_COUNT_DOM_FAIL = 'TICKETS_COUNT_DOM_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'