import axios from 'axios';
import { HOST } from 'constants/ApiConstant';
import {
  PROFILE_DATA_REQUEST,
  PROFILE_DATA_SUCCESS,
  PROFILE_DATA_FAIL,
	UPDATE_ME_REQUEST,
	UPDATE_ME_SUCCESS,
	UPDATE_ME_FAIL,
  CLEAR_ERRORS
} from '../constants/Profile'

export const getProfileData = () => async (dispatch) => {
	try {
		dispatch({ type: PROFILE_DATA_REQUEST })
		const data = await axios.get(`${HOST}/operator/me`)
    console.log('Profile data')
    console.log(data)
		dispatch({ type: PROFILE_DATA_SUCCESS, payload: data })
	} catch (error) {
		dispatch({ type: PROFILE_DATA_FAIL, payload: error.response.data })
	}
}

export const updateMe = (profileData) => async (dispatch) => {
	try {
    dispatch({ type: UPDATE_ME_REQUEST })
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    const { data } = await axios.patch(`${HOST}/operator/me`, profileData, config)
    dispatch({ type: UPDATE_ME_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: UPDATE_ME_FAIL, payload: error.response.data })
  }
}

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS })
}