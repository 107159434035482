import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AppLayout from "layouts/app-layout";
import AuthViews from 'views/auth-views'
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import {
  APP_PREFIX_PATH,
  HOME_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  TOKEN_PREFIX,
  REFRESH_TOKEN_PREFIX,
} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import axios from 'axios';

export const Views = () => {
  const { user, theme } = useSelector(state => state)
  const { token, refreshToken } = user
  const { locale, direction } = theme
  const currentAppLocale = AppLocale[locale]

  useBodyClass(`dir-${direction}`)

  useEffect(() => {
    if (token && refreshToken) {
      axios.defaults.headers.common[TOKEN_PREFIX] = token
      axios.defaults.headers.common[REFRESH_TOKEN_PREFIX] = refreshToken
    }
  }, [token, refreshToken])

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Router>
          <Switch>
            <Route path={AUTH_PREFIX_PATH}>
              {token ? <Redirect to={HOME_PREFIX_PATH} /> : <AuthViews />}
            </Route>
            {token ? <Route path={`${APP_PREFIX_PATH}/*`} component={AppLayout} /> : null}
            <Route path='/'>
              <Redirect to={token ? HOME_PREFIX_PATH : AUTH_PREFIX_PATH} />
            </Route>
          </Switch>
        </Router>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default Views;